import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  VStack,
  Textarea,
  Select,
  useToast
} from '@chakra-ui/react';
import axios from 'axios'; 

function EventForm() {
  const [eventData, setEventData] = useState({
    question: '',
    eventDetails: {
      description: '',
      image: '',
      eventType: 'crypto',
    },
    options: [{ option: "Yes" }, { option: "No" }],
    optionOne: '',
    optionTwo: '',
    tokenName: '',
    tokenPrice: '',
    poolType: '',
    allowedTokens: [],
    tokenContractAddress: '',
    cryptoId: '',
    condition: 'above',
    targetValue: '',
    iconName: '',
    startDate: '',
    deadline: '',
    status: 'open'
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const inputStyleProps = {
    variant: 'filled',
    _focus: { borderColor: 'white' },
    color: 'black'
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in eventData.eventDetails) {
      setEventData(prev => ({
        ...prev,
        eventDetails: { ...prev.eventDetails, [name]: value }
      }));
    } else {
      setEventData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleTokenChange = (e) => {
    setEventData(prev => ({ ...prev, allowedTokens: Array.from(e.target.selectedOptions, option => option.value) }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullEventData = {
      ...eventData,
      options: [{ option: 'Yes' }, { option: 'No' }] 
    };
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api-v2.scorefam.org/api/admin/create-event', 
        fullEventData,
        {
          headers: {
          'x-api-key': process.env.REACT_APP_ADMIN_API_KEY
          }
        }
      );
      toast({
        title: "Event created.",
        description: "We've created your event for you.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: "Failed to create event.",
        description: `Error: ${error.response ? error.response.data.message : error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
   <Box p={5} shadow="md" borderWidth="1px" bg="gray.800">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="flex-start">
          <FormControl isRequired>
            <FormLabel htmlFor="question" color="white">Event Question</FormLabel>
            <Input id="question" name="question" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="description" color="white">Event Description</FormLabel>
            <Textarea id="description" name="description" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="image" color="white">Event Image URL</FormLabel>
            <Input id="image" name="image" onChange={handleChange} placeholder="http://example.com/image.jpg" {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="cryptoId" color="white">Crypto Name</FormLabel>
            <Input id="cryptoId" name="cryptoId" onChange={handleChange} placeholder="Bitcoin" {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="tokenContractAddress" color="white" isRequired>Token Contract Address</FormLabel>
            <Input id="tokenContractAddress" name="tokenContractAddress" onChange={handleChange} placeholder="0x3456......." {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="optionOne" color="white">Option One Name</FormLabel>
            <Input id="optionOne" name="optionOne" onChange={handleChange} placeholder="Yes" {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="optionTwo" color="white">Option Two Name</FormLabel>
            <Input id="optionTwo" name="optionTwo" onChange={handleChange} placeholder="No" {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="iconName" color="white">Icon Name</FormLabel>
            <Input id="iconName" name="iconName" onChange={handleChange} placeholder="Djenn" {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="eventType" color="white">Event Type</FormLabel>
            <Select id="eventType" name="eventType" onChange={handleChange} defaultValue="crypto" {...inputStyleProps}>
              <option value="real-world">Real World</option>
              <option value="crypto">Cryptocurrency</option>
              <option value="politics">Politics</option>
              <option value="blast">Blast Ecosystem</option>
              <option value="sports">Sport</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="condition" color="white">Condition</FormLabel>
            <Select id="condition" name="condition" onChange={handleChange} defaultValue="above" {...inputStyleProps}>
              <option value="above">Above</option>
              <option value="below">Below</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="tokenName" color="white">Token Name</FormLabel>
            <Input id="tokenName" name="tokenName" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
           <FormControl>
            <FormLabel htmlFor="tokenPrice" color="white">Token Price</FormLabel>
            <Input id="tokenPrice" name="tokenPrice" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="poolType" color="white">Pool Type</FormLabel>
            <Select id="poolType" name="poolType" onChange={handleChange} defaultValue="Scorefam" {...inputStyleProps}>
              <option value="scorefam">Scorefam</option>
              <option value="blastoff">BlastOff</option>
              <option value="blasterswap">Blaster</option>
              <option value="bladeswap">Bladeswap</option>
              <option value="thrusterfi">ThrusterFi</option>
              <option value="andy">Andy</option>
              <option value="djenn">Djenn</option>
              <option value="wasabi">Wasabi</option>
              <option value="yes">Yes</option>
              <option value="iconcast">Iconcast</option>
              <option value="partner">Partner</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="targetValue" color="white">Target Value</FormLabel>
            <Input id="targetValue" name="targetValue" type="number" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="startDate" color="white">Start Date</FormLabel>
            <Input type="datetime-local" id="startDate" name="startDate" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="deadline" color="white">Deadline</FormLabel>
            <Input type="datetime-local" id="deadline" name="deadline" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="twitter" color="white">Twitter Handle</FormLabel>
            <Input id="twitter" name="twitter" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="telegram" color="white">Telegram Handle</FormLabel>
            <Input id="telegram" name="telegram" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="discord" color="white">Discord Handle</FormLabel>
            <Input id="discord" name="discord" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="website" color="white">Website</FormLabel>
            <Input id="website" name="website" onChange={handleChange} {...inputStyleProps} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="status" color="white">Status</FormLabel>
            <Select id="status" name="status" onChange={handleChange} defaultValue="upcoming" {...inputStyleProps}>
              <option value="upcoming">Upcoming</option>
              <option value="open">Open</option>
              <option value="closed">Closed</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </Select>
          </FormControl>
          <Button type="submit" colorScheme="blue" isLoading={loading}>Create Event</Button>
        </VStack>
      </form>
    </Box>
  );
}

export default EventForm;
